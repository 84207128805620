import axios from "axios";
import config from "@config/config";

const getUpiClientConfig = async (sfId, providerId, order) => {
  const transactionHashEndpoint =
    config.serverlessUrl + "payment/upi-manual/create";
  const response = await axios.post(transactionHashEndpoint, {
    sfId,
    order,
    providerId,
  });
  return response.data;
};

export default getUpiClientConfig;
