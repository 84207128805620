import { Module } from "vuex";
import process from "../helpers/process";
import getUpiClientConfig from "../services/getClientConfig";

export const upiManualStore: Module<any, any> = {
  namespaced: true,
  state: {
    storeId: null,
    currentConfig: null,
  },
  getters: {
    storeId: (state) => state.storeId,
    currentConfig: (state) => state.currentConfig,
  },
  mutations: {
    setStoreId(state, storeId) {
      state.storeId = storeId;
    },
    setCurrentConfig(state, config) {
      state.currentConfig = config;
    },
  },
  actions: {
    init({ commit, dispatch, rootState }) {
      commit("setStoreId", rootState.storeId);
    },
    async launch({ getters, commit }, { order, config }) {
      let orderStatus = {
        status: "initialized",
        order: null,
        error: null,
      };
      const sfId = getters.storeId;
      try {
        const upiConfig = await getUpiClientConfig(sfId, config.id, order);
        commit("setCurrentConfig", upiConfig);
        orderStatus = await process(sfId, config.id, order);
      } catch (error) {
        orderStatus.status = "error";
        orderStatus.error =
          "Could not place your order. Please try again after some time.";
        console.error(error);
      }
      return orderStatus;
    },
  },
};
